import { TextField } from '@material-ui/core'
import { useState } from 'react'
import { useAsyncDebounce } from 'react-table'

const QueryFilter = ({ column: { filterValue, setFilter } }) => {
  const [value, setValue] = useState(filterValue)

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 200)

  return (
    <>
      <TextField
        size='small'
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Filter...`}
        inputProps={{ style: { fontSize: 12, minWidth: 62, maxWidth: 125 } }}
        variant='standard'
      />
    </>
  )
}

export default QueryFilter

import { instance } from './axios'

const vaultAPI = {
  getVaultExchanges: () => {
    return instance
      .get('/vault')
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  updateExchangeKeys: (id, keys) => {
    return instance.patch(`/vault/${id}`, { id, keys }).then(
      (res) => {
        return res?.data
      },
      (error) => {
        return error
      },
    )
  },
  checkExchangeKeys: (id, keys) => {
    return instance.post(`/check/${id}`, { id, keys })
  },
}

export default vaultAPI

import { formatDateTime, formatFloat } from '../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Network',
    accessor: 'network',
  },
  {
    Header: 'Exchange from',
    accessor: 'exchangeFrom',
  },
  {
    Header: 'Exchange to',
    accessor: 'exchangeTo',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Min profit',
    accessor: 'minProfit',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'PMin',
    accessor: 'pmin',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Profit',
    accessor: 'profit',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'СEX symbol',
    accessor: 'cexSymbolLeft',
    Cell: ({ value, row }) => value + row.original.cexSymbolRight,
  },
  {
    Header: 'CEX price',
    accessor: 'cexPrice',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'CEX total',
    accessor: 'cexTotal',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'DEX symbol',
    accessor: 'dexSymbolLeft',
    Cell: ({ value, row }) => value + row.original.dexSymbolRight,
  },
  {
    Header: 'DEX price',
    accessor: 'dexPrice',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'DEX total',
    accessor: 'dexTotal',
    Cell: ({ value }) => formatFloat(value),
    sortType: 'alphanumericFalsyLast',
  },
  {
    Header: 'Date',
    accessor: 'timestamp',
    Cell: ({ value }) => formatDateTime(value, true),
  },
]

import {
  Alert,
  Backdrop,
  Button,
  Fade,
  Modal,
  Paper,
  Popover,
  Snackbar,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React, { useMemo, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import botAPI from '../../api/bot-api.js'
import BotGraph from '../BotGraph/BotGraph.jsx'
import Preloader from '../Preloader/Preloader'
import BotEditor from './BotEditor/BotEditor'
import s from './BotTable.module.css'
import { COLUMNS } from './columns'

export const UNARCHIVED = 'unarchived'
export const ARCHIVED = 'archived'

const BotTable = ({ botsType = UNARCHIVED, data, setData, isFetching, refreshBots }) => {
  const columns = useMemo(() => COLUMNS, [])
  const [bot, setBot] = useState({ id: null, fill: null, show: false })
  const [isStatusChanging, setIsStatusChanging] = useState(false)
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const [confirmAnchor, setConfirmAnchor] = useState(null)
  const [botId, setBotId] = useState(null)
  const [graph, setGraph] = useState({ show: false, botId: null, operationId: null })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
    },
    useSortBy,
  )

  const openBotEditor = ({ id = null }) => setBot({ id, fill: data.find((item) => item.id === id), show: true })

  const closeBotEditor = () => setBot((prev) => ({ ...prev, show: false }))

  const switchBotStatus = async (id, newStatus) => {
    setIsStatusChanging(true)

    try {
      const response = await botAPI.changeBotStatus(id, newStatus)
      if (response === 'status changed') {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'Bot status changed!',
          severity: 'success',
        }))
        setData((prev) => prev.map((bot) => (bot.id === id ? { ...bot, status: newStatus } : bot)))
      } else {
        let error = ''
        for (let key in response?.data) {
          if (response?.data.hasOwnProperty(key)) {
            error += `\n${response.data[key][0]}`
          }
        }
        throw Error(error)
      }
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
    } finally {
      setIsStatusChanging(false)
    }
  }

  const changeBotArchive = async () => {
    try {
      const response = botsType === UNARCHIVED ? await botAPI.archiveBot(botId) : await botAPI.unarchiveBot(botId)
      if (response === 'bot is archived') {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'Bot archivation status changed!',
          severity: 'success',
        }))
        refreshBots()
      } else {
        let error = ''
        for (let key in response?.data) {
          if (response?.data.hasOwnProperty(key)) {
            error += `\n${response.data[key][0]}`
          }
        }
        throw Error(error)
      }
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
    }
  }

  const openGraph = (botId, operationId) => {
    setGraph({
      show: true,
      botId,
      operationId,
    })
  }

  const closeGraph = () => setGraph((prev) => ({ ...prev, show: false }))

  const openConfirmArchivation = (event, id) => {
    setConfirmAnchor(event.currentTarget)
    setBotId(id)
  }

  const handlePopupClose = () => setPopup((popup) => ({ ...popup, open: false }))

  const showConfirm = Boolean(confirmAnchor)

  if (isFetching) return <Preloader />

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2}>
      <div className={s.heading}>
        <Typography variant={'h5'}>{botsType === ARCHIVED ? 'Archived bots' : 'Bot List'}</Typography>
        {botsType === UNARCHIVED && (
          <div className={s.createBot} onClick={openBotEditor}>
            <i className='fas fa-plus' /> Create a bot
          </div>
        )}
      </div>
      <TableContainer className={`${s.table} ${botsType === ARCHIVED && s.disabled}`}>
        {rows.length > 0 && (
          <MuiTable stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <div className={s.tableHeadCell}>
                          <span
                            style={{
                              userSelect: 'none',
                            }}
                          >
                            {column.render('Header')}
                          </span>
                          <i
                            style={{
                              fontSize: '11px',
                              marginLeft: '2px',
                              color: !column.isSorted && 'transparent',
                            }}
                            className={`fas fa-chevron-${
                              column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'
                            }`}
                          />
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell', {
                            isStatusChanging,
                            openBotEditor,
                            switchBotStatus,
                            changeBotArchive,
                            botsType,
                            openConfirmArchivation,
                            openGraph,
                          })}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        )}
        {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
      </TableContainer>
      <BotEditor
        archived={botsType === ARCHIVED}
        refreshBots={refreshBots}
        onClose={closeBotEditor}
        fill={bot.fill}
        id={bot.id}
        show={bot.show}
        setPopup={setPopup}
      />
      <Snackbar
        open={popup.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handlePopupClose}
      >
        <Alert onClose={handlePopupClose} severity={popup.severity}>
          <div className='column' style={{whiteSpace: 'pre-wrap'}}>
            {popup.message}
          </div>
        </Alert>
      </Snackbar>
      <Popover
        open={showConfirm}
        anchorEl={confirmAnchor}
        onClose={() => setConfirmAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 6 }} className='column'>
          <Typography>Archive this bot?</Typography>
          <Button
            style={{ marginTop: 8, marginLeft: 'auto' }}
            size='small'
            onClick={() => {
              changeBotArchive()
              setConfirmAnchor(null)
            }}
          >
            Confirm
          </Button>
        </div>
      </Popover>
      <Modal
        aria-labelledby='modal-graph'
        aria-describedby='modal-graph'
        className={s.modal}
        open={graph.show}
        onClose={closeGraph}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={graph.show}>
          <Paper className={s.graph}>
            <BotGraph botId={`${graph.botId}|${graph.operationId}`} handleClose={closeGraph} />
          </Paper>
        </Fade>
      </Modal>
    </Paper>
  )
}

export default BotTable

import { redirectToCognitoPage } from '../../api/auth-api'

const AwsCallback = () => {
  return (
    <div className='column'>
      <div>
        <h2>Authorizing</h2>
      </div>
      <div>
        <h3>
          If nothing happens, press{' '}
          <b onClick={() => redirectToCognitoPage()} style={{ cursor: 'pointer' }}>
            HERE
          </b>
        </h3>
      </div>
    </div>
  )
}

export default AwsCallback

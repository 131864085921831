import config from '../config'
import axios from 'axios'
import * as qs from 'qs'
import { generateCodeChallenge, generateCodeVerifier } from '../utilities/encoding'

const cognitoInstance = axios.create({
  baseURL: config.cognito.provider,
})

const fetchToken = (data) => {
  return cognitoInstance
    .post('/oauth2/token', qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((res) => res)
    .catch((err) => console.error('Request token:', err))
}

export const redirectToCognitoPage = (action = 'login') => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = generateCodeChallenge(codeVerifier)
  localStorage.removeItem('token')
  localStorage.removeItem('refresh')
  localStorage.removeItem('lastAuth')
  localStorage.removeItem('expiresIn')
  localStorage.removeItem('username')
  localStorage.setItem('authenticated', 'false')
  localStorage.setItem('codeVerifier', codeVerifier)
  localStorage.setItem('codeChallenge', codeChallenge)

  const authURL = `${config.cognito.provider}/${action}?response_type=code&client_id=${config.cognito.clientId}&redirect_uri=${config.cognito.redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256`
  window.location.replace(authURL)
}

export async function awsCallTokenEndpoint(grantType, accessToken, codeVerifier) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.clientId,
    client_secret: config.cognito.clientSecret,
    code: accessToken,
    scope: 'funbot/arbitrage.ui',
    redirect_uri: config.cognito.redirectUri,
    code_verifier: codeVerifier,
  }

  const awsResponse = await fetchToken(data)

  if (awsResponse?.status === 200) {
    return awsResponse?.data
  }
}

export async function awsCallTokenRefresh(grantType, refreshToken) {
  const data = {
    grant_type: grantType,
    client_id: config.cognito.clientId,
    refresh_token: refreshToken,
  }

  const awsResponse = await fetchToken(data)
  if (awsResponse?.status === 200) {
    return awsResponse?.data
  }
}

const getUserInfo = (token) => {
  return axios
    .post(
      'https://cognito-idp.eu-west-1.amazonaws.com/',
      { AccessToken: token },
      {
        headers: {
          'X-Amz-target': 'AWSCognitoIdentityProviderService.GetUser',
          'Content-Type': 'application/x-amz-json-1.1',
        },
      },
    )
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

export const getAwsToken = async (requestCallback) => {
  const data = await requestCallback()
  const userInfo = await getUserInfo(data?.access_token)

  const username =
    userInfo.UserAttributes?.find((attr) => attr.Name === 'name')?.Value ||
    userInfo.UserAttributes?.find((attr) => attr.Name === 'email')?.Value

  localStorage.setItem('token', data.access_token)
  if (data && data.refresh_token) localStorage.setItem('refresh', data.refresh_token)
  localStorage.setItem('authenticated', 'true')
  localStorage.setItem('lastAuth', JSON.stringify(Number(new Date())))
  localStorage.setItem('expiresIn', JSON.stringify(data.expires_in * 1000))
  localStorage.setItem('username', username)
}

export const awsLogout = async () => {
  redirectToCognitoPage('logout')
}

export const isAuthExpired = () => {
  const lastAuth = localStorage.getItem('lastAuth')
  const expiresIn = localStorage.getItem('expiresIn')

  if (new Date() - lastAuth > expiresIn - 300000) return true
  return false
}

import DetailedBookkeepingTable from './components/DetailedBookkeepingTable'

const Bookkeeping = () => {
  return (
    <>
      <DetailedBookkeepingTable />
    </>
  )
}

export default Bookkeeping

import { Button } from '@material-ui/core'
import { useHistory } from 'react-router'

const BackButton = () => {
  const history = useHistory()

  return (
    <Button style={{ alignSelf: 'flex-end' }} onClick={() => history.goBack()}>
      Back
    </Button>
  )
}

export default BackButton

const SET_INITIALIZED = 'settings/SET_INITIALIZED'
const SET_SETTINGS = 'settings/SET_SETTINGS'
const SET_AVAILABLE_EXCHANGES = 'settings/SET_AVAILABLE_EXCHANGES'

const initialState = {
  initialized: false,
  savedExchanges: [],
  savedPairs: [],
  availableExchanges: [],
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIALIZED: {
      return {
        ...state,
        initialized: action.initialized,
      }
    }
    case SET_SETTINGS: {
      return {
        ...state,
        savedExchanges: action.savedExchanges,
        savedPairs: action.savedPairs,
      }
    }
    case SET_AVAILABLE_EXCHANGES: {
      return {
        ...state,
        availableExchanges: action.availableExchanges,
      }
    }
    default:
      return state
  }
}

export default settingsReducer

export const settingsActions = {
  setInitialized: (initialized) => ({ type: SET_INITIALIZED, initialized }),
  setSettings: (savedExchanges = [], savedPairs = []) => ({
    type: SET_SETTINGS,
    savedExchanges,
    savedPairs,
  }),
  setAvailableExchanges: (availableExchanges) => ({
    type: SET_AVAILABLE_EXCHANGES,
    availableExchanges,
  }),
}

import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import filtersReducer from './reducers/filters-reducer'
import settingsReducer from './reducers/settings-reducer'

const rootReducer = combineReducers({
  filters: filtersReducer,
  settings: settingsReducer,
})

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))

window.store = store

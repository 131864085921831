import { useCallback, useEffect, useState, useMemo, Fragment } from 'react'
import { Container, Button, Paper, Typography } from '@material-ui/core'
import Preloader from '../../../components/Preloader/Preloader'
import { COLUMNS } from './columns'
import s from './DetailedBookkeepingTable.module.css'

import TextField from '@mui/material/TextField'
import {DateRangePicker} from 'react-date-range'
import Box from '@mui/material/Box'

// GetBookkeepingAPI
import bookkeepingApi from '../../../api/bookkeeping-api'
import moment from 'moment'
import Table from 'components/Table/Table'
import { Popover } from '@mui/material'

const DetailedBookkeepingTable = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState([])
  const [profit, setProfit] = useState([])
  const [period, setPeriod] = useState({startDate: new Date(), endDate: new Date(), key: 'selection'})
  const [anchorEl, setAnchorEl] = useState(null);

  const openRangePicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeRangePicker = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-range' : undefined;

  const parseData = (raw) => {
    return raw?.map((item) => ({
      ...item,
      dexDuration:
        item.transaction.dexResponseTime !== '1970-01-01 00:00:00 +0000 +0000'
          ? moment
              .utc(
                moment(item.transaction.dexResponseTime, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').diff(
                  moment(item.transaction.dexRequestTime, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000'),
                ),
              )
              .format('HH:mm:ss.SSS')
          : 'TX Failed',
      cexDuration:
        item.transaction.cexResponseTime !== '1970-01-01 00:00:00 +0000 +0000'
          ? moment
              .utc(
                moment(item.transaction.cexResponseTime, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').diff(
                  moment(item.transaction.cexRequestTime, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000'),
                ),
              )
              .format('HH:mm:ss.SSS')
          : 'TX Failed',
      totalDuration:
        item.transaction.cexResponseTime !== '1970-01-01 00:00:00 +0000 +0000'
          ? moment
              .utc(
                moment(item.transaction.cexResponseTime, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').diff(
                  moment(item.transaction.dexRequestTime, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000'),
                ),
              )
              .format('HH:mm:ss.SSS')
          : 'TX Failed',
    }))
  }

  const fetchData = useCallback(async () => {
    const response = await bookkeepingApi.getBookkeeping(1, 20)
    if (response?.records?.length > 0) {
      setData(parseData(response.records))
      setProfit(response.profit)
    }
  }, [])

  const fetchToday = useCallback(async () => {
    const startTime = moment().utc().format('YYYY-MM-DD[T]00:00')
    const endTime = moment().utc().format('YYYY-MM-DD[T]23:59')
    const response = await bookkeepingApi.getBookkeepingByPeriod(
      moment.utc(startTime).toISOString(),
      moment.utc(endTime).toISOString(),
    )
    if (response?.records?.length > 0) {
      setData(parseData(response.records))
      setProfit(response.profit)
    }
  }, [])

  const fetchYesterday = useCallback(async () => {
    const startTime = moment().utc().subtract(1, 'days').format('YYYY-MM-DD[T]00:00')
    const endTime = moment().utc().subtract(1, 'days').format('YYYY-MM-DD[T]23:59')
    const response = await bookkeepingApi.getBookkeepingByPeriod(
      moment.utc(startTime).toISOString(),
      moment.utc(endTime).toISOString(),
    )
    if (response?.records?.length > 0) {
      setData(parseData(response.records))
      setProfit(response.profit)
    }
  }, [])

  const fetchPeriod = useCallback(async (dirtyStartTime, dirtyEndTime) => {
    console.log(dirtyStartTime, dirtyEndTime)
    const startTime = moment(new Date(dirtyStartTime)).format('YYYY-MM-DD[T]00:00')
    const endTime = moment(new Date(dirtyEndTime)).format('YYYY-MM-DD[T]23:59')
    console.log(startTime, endTime)
    const response = await bookkeepingApi.getBookkeepingByPeriod(
      moment.utc(startTime).toISOString(),
      moment.utc(endTime).toISOString(),
    )
    if (response?.records?.length > 0) {
      setData(parseData(response.records))
      setProfit(response.profit)
    }
  }, [])

  const handleViewToday = useCallback(async () => {
    console.log('view today')
    setIsFetching(true)
    fetchToday()
    setIsFetching(false)
  }, [])

  const handleViewYesterday = useCallback(async () => {
    console.log('view yesterday')
    setIsFetching(true)
    fetchYesterday()
    setIsFetching(false)
  }, [])

  const handleViewLast = useCallback(async () => {
    console.log('view last')
    setIsFetching(true)
    fetchData()
    setIsFetching(false)
  }, [])

  const handleViewPeriod = useCallback(async (period) => {
    console.log('view period')
    console.log(period)
    setIsFetching(true)
    fetchPeriod(period.startDate, period.endDate)
    setIsFetching(false)
  }, [])

  // handleViewLast

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      fetchData()
      setIsFetching(false)
    })()
  }, [fetchData])

  const dateButtons = useMemo(() =>[
    {
      label: 'Today',
      onClick: handleViewToday
    },
    {
      label: 'Yesterday',
      onClick: handleViewYesterday
    },
    {
      label: 'Last 20',
      onClick: handleViewLast
    },
    {
      label: 'Select Range',
      onClick: openRangePicker
    },
  ], [handleViewLast, handleViewToday, handleViewYesterday])

  if (isFetching) return <Preloader />

  return (
    <Container fluid='true' maxWidth='0'>
      <Paper className={s.paper + ' flex-column'} elevation={2}>
        <div className={s.heading}>
          <Typography variant={'h5'} className={s.rangeButtons}>
            Transactions
            {dateButtons.map(({label, onClick}) => (
              <Button variant='text' onClick={onClick}>
                {label}
              </Button>
            ))}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={closeRangePicker}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <DateRangePicker
                startDatePlaceholder='From'
                endDatePlaceholder='To'
                ranges={[period]}
                dateDisplayFormat={'dd/MM/yyyy'}
                fixedHeight
                staticRanges={[]}
                inputRanges={[]}
                onChange={(newValue) => {
                  setPeriod(newValue.selection)
                  handleViewPeriod(newValue.selection)
                }}
              />
            </Popover>
          </Typography>

          <div className={s.profit}>Profit: {(profit * 1).toFixed(2)}</div>
        </div>
        <Table data={data} columns={columns} enableFilters />
      </Paper>
    </Container>
  )
}

export default DetailedBookkeepingTable

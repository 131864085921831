import moment from 'moment'
import QueryFilter from '../../../components/Table/components/QueryFilter'

const renderFilter = (info) => <QueryFilter key='FilterInput' {...info} />

export const COLUMNS = [
  {
    Header: 'Bot ID',
    accessor: 'requestId',
    Cell: ({ value, row }) => (
      <a
        href={`https://polygonscan.com/tx/${row.original.transaction?.dexResponse?.txHash}`}
        target={'_blank'}
        rel='noreferrer'
      >
        <span> {value.substring(0, value.indexOf('_'))}</span>
      </a>
    ),
    Filter: renderFilter,
  },
  {
    Header: 'Tx ID',
    // accessor: 'requestId',
    Cell: ({ row }) => <span> {row.original.requestId}</span>,
    disableFilters: true,
  },
  {
    Header: 'Pair',
    accessor: 'transaction.requestData.dexSymbolLeft',
    Cell: ({ row }) => (
      <span>
        {row.original.transaction.requestData.dexSymbolLeft}
        {row.original.transaction.requestData.dexSymbolRight}
      </span>
    ),
    Filter: renderFilter,
  },
  {
    Header: 'Direction',
    accessor: 'transaction.requestData.direction',
    disableFilters: true,
  },
  {
    Header: 'From',
    accessor: 'transaction.requestData.exchangeFrom',
    Filter: renderFilter,
  },
  {
    Header: 'To',
    accessor: 'transaction.requestData.exchangeTo',
    disableFilters: true,
  },
  {
    Header: 'Network',
    accessor: 'transaction.requestData.network',
    Filter: renderFilter,
  },
  {
    Header: 'Sum bought',
    accessor: 'usdtSpent',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
    disableFilters: true,
  },
  {
    Header: 'Sum sold',
    accessor: 'usdtReceived',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
    disableFilters: true,
  },
  {
    Header: 'Dex fee',
    accessor: 'gas',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
    disableFilters: true,
  },
  {
    Header: 'Cex fee',
    accessor: 'cexFee',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
    disableFilters: true,
  },
  {
    Header: 'Profit',
    accessor: 'total',
    Cell: ({ value }) =>
      value < 0 ? (
        <span style={{ color: 'red' }}>{(value * 1).toFixed(2)}</span>
      ) : (
        <span style={{ color: 'green' }}>{(value * 1).toFixed(2)}</span>
      ),
    disableFilters: true,
  },
  {
    Header: 'Profit Raw',
    Cell: ({ row }) =>
      // row.original?.total ? <span>{(row.original.total*1).toFixed(5)}</span> : ""
      Number(row.original.usdtSpent) && Number(row.original.total) ? (
        <span>{((row.original.total / row.original.usdtSpent) * 100).toFixed(3)}%</span>
      ) : (
        '-'
      ),
    disableFilters: true,
  },
  {
    Header: 'Date',
    accessor: 'transaction.createdAt',
    Cell: ({ value }) => <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('YYYY-MM-DD')}</span>,
    disableFilters: true,
  },
  {
    Header: 'Dex Request Time',
    accessor: 'transaction.dexRequestTime',
    Cell: ({ value }) => <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('HH:mm.ss.SSS')}</span>,
    disableFilters: true,
  },
  {
    Header: 'Dex Response Time',
    accessor: 'transaction.dexResponseTime',
    Cell: ({ value }) => <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('HH:mm.ss.SSS')}</span>,
    disableFilters: true,
  },
  {
    Header: 'Dex Duration',
    accessor: 'dexDuration',
    disableFilters: true,
  },
  {
    Header: 'Cex Request Time',
    accessor: 'transaction.cexRequestTime',
    Cell: ({ value }) => <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('HH:mm.ss.SSS')}</span>,
    disableFilters: true,
  },
  {
    Header: 'Cex Response Time',
    accessor: 'transaction.cexResponseTime',
    Cell: ({ value }) => <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('HH:mm.ss.SSS')}</span>,
    disableFilters: true,
  },
  {
    Header: 'Cex Duration',
    accessor: 'cexDuration',
    disableFilters: true,
  },
  {
    Header: 'Total Duration',
    accessor: 'totalDuration',
    disableFilters: true,
  },
]

import { instance } from './axios'

const topDealsAPI = {
  savePairs: (pairs) => {
    return instance
      .post(`/set-top-deals-pairs`, { id: pairs })
      .then((res) => {
        return res.data
      })
      .catch((err) => console.log(err))
  },
  getSavedPairs: () => {
    return instance
      .get(`/saved-top-deals-pairs`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },
  getTopDeals: () => {
    return instance
      .get(`/get-top-deals`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
}

export default topDealsAPI

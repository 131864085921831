import axios from 'axios'
import { redirectToCognitoPage } from './auth-api'

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
})

instance.interceptors.request.use(function (config) {
  const headers = authHeader()
  if (headers) {
    config.headers = { ...config.headers, ...headers }
    config.headers.Accept = 'application/json'
  }
  return config
})

//handling 401
let confirmCount = 0
instance.interceptors.response.use(undefined, (err) => {
  const error = err.response

  if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
    confirmCount += 1
    if (confirmCount <= 1 && window.confirm('Server returned error 401. Do you want to authorize again?')) {
      redirectToCognitoPage()
    }
  }
  return error
})

export function authHeader() {
  const token = localStorage.getItem('token')
  if (token) {
    return { Authorization: 'Bearer ' + token }
  }
  return {}
}

/*export function authHeader() {
    const user = getUser();

    if (user?.accessToken) {
        return {
            Accept: "application/json",
            Authorization: "Bearer " + user.accessToken,
        };
    } else {
        return { Accept: "application/json" };
    }
}*/

import { Button, Paper } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Select from 'react-select'
import arbitrageAPI from '../../../api/arbitrage-api'
import BackButton from '../../../components/BackButton/BackButton'
import useDebounce from '../../../hooks/use-debounce'
import { settingsActions } from '../../../redux/reducers/settings-reducer'

const HomeSettings = () => {
  const savedExchanges = useSelector((state) => state.settings.savedExchanges)
  const savedPairs = useSelector((state) => state.settings.savedPairs)

  const [pairs, setPairs] = useState(savedPairs)
  const [pairQuery, setPairQuery] = useState('')
  const [isPairFetching, setIsPairFetching] = useState(false)
  const [pairsOptions, setPairsOptions] = useState([])
  const [exchanges, setExchanges] = useState(savedExchanges)
  const exchangesOptions = useSelector((state) => state.settings.availableExchanges)
  const [isSaving, setIsSaving] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const debouncedPairQuery = useDebounce(pairQuery, 400)

  useEffect(() => {
    if (!debouncedPairQuery) {
      return
    }

    ;(async () => {
      setIsPairFetching(true)
      const data = await arbitrageAPI.getPairs(debouncedPairQuery)
      setPairsOptions(data?.data)
      setIsPairFetching(false)
    })()
  }, [debouncedPairQuery])

  const handlePairsChange = (value) => setPairs(value)

  const handlePairQueryChange = (value) => setPairQuery(value)

  const handleExchangesChange = (option) => setExchanges(option)

  const handleSave = async () => {
    setIsSaving(true)

    const savePairsSuccess = await arbitrageAPI.savePairs(pairs.map((p) => p.id))
    const saveExchangesSuccess = await arbitrageAPI.saveExchanges(exchanges.map((ex) => ex.id))
    dispatch(settingsActions.setSettings(saveExchangesSuccess.data, savePairsSuccess.data))

    setIsSaving(false)
    alert('Setting saved successfully!')
    history.push('/')
  }

  return (
    <Paper className={'flex-column'} elevation={2} style={{ padding: 8 }}>
      <BackButton />
      <h4>Home page settings</h4>
      <div className={'flex-row flex-wrap'}>
        <Select
          className={'input'}
          styles={{
            valueContainer: (base) => ({
              ...base,
              maxHeight: 200,
              overflow: 'auto',
            }),
          }}
          name='Select exchanges'
          options={exchangesOptions}
          value={exchanges}
          getOptionLabel={(x) => x.name}
          getOptionValue={(x) => x.id}
          onChange={handleExchangesChange}
          isMulti
          closeMenuOnSelect={false}
        />
        <Select
          className={'input'}
          styles={{
            valueContainer: (base) => ({
              ...base,
              maxHeight: 200,
              overflow: 'auto',
            }),
          }}
          name='Pairs'
          options={pairsOptions}
          value={pairs}
          getOptionLabel={(x) => x.name}
          getOptionValue={(x) => x.id}
          onChange={handlePairsChange}
          onInputChange={handlePairQueryChange}
          isMulti
          cacheOptions
          defaultOptions
          closeMenuOnSelect={false}
          isLoading={isPairFetching}
        />
      </div>
      <Button disabled={isSaving} onClick={handleSave} variant={'outlined'} color='inherit'>
        Save
      </Button>
    </Paper>
  )
}

export default HomeSettings

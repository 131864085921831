import HistoryTable from './components/HistoryTable/HistoryTable'

const BotSignals = () => {
  return (
    <>
      <HistoryTable />
    </>
  )
}

export default BotSignals

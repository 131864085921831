import React, { useCallback, useEffect, useMemo, useState } from 'react'
import s from './BlocksTable.module.css'
import { Alert, Divider, IconButton, Paper, Snackbar, Typography } from '@material-ui/core'
import Preloader from '../Preloader/Preloader'
import blocksApi from '../../api/blocks-api'

const BlocksTable = () => {
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  
  const parseData = (data) => {
    return [...(data?.blocksActive?.map((item) => ({ ...item, active: true })) || [])]
  }

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)

      const res = await blocksApi.getBlocks()
      const parsedData = parseData(res)
        
      setData(parsedData)
      if (parsedData.length < 1) {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'System runs smoothly and earns money!',
          severity: 'success',
        }))
      }

      setIsFetching(false)
    })()
  }, [])

  const handleStatusChange = async (block) => {
    let res
    if (block.active) {
      res = await blocksApi.unblockExecution(block.name, block.exchange)
      if (res) {
        setData((prev) => prev.map((item) => (item.id === block.id ? { ...item, active: false } : item)))
      }
      return
    }

    res = await blocksApi.blockExecution(block.name, block.exchange)
    if (res) {
      setData((prev) => prev.map((item) => (item.id === block.id ? { ...item, active: true } : item)))
    }
  }

  const handlePopupClose = () => setPopup((popup) => ({ ...popup, open: false }))

  const closeBlockList = () => {
    setData([])
  }

  const BlockItem = useCallback(({block}) => {
    return (
      <>
        <Divider />
        <div className='flex-row' style={{gap: 12}}>
          <IconButton onClick={() => handleStatusChange(block)}>
            <i className='fas fa-power-off' style={{ color: block.active ? 'green' : 'red' }} />
          </IconButton>
          <div className='column'>
            <Typography style={{fontWeight: 700}}>{block.name}</Typography>
            <Typography style={{wordBreak: 'break-word'}}>{block.exchange}</Typography>
          </div>
        </div>
      </>
    )
  }, [])

  if (data?.length) {
    return (
      <Paper style={{marginBottom: 24}}>
        <div className={s.blockList}>
          <div className={s.heading}>
            <Typography variant={'h5'} gutterBottom>Blocks</Typography>
            <IconButton size='small' onClick={closeBlockList}>
              <i className='fa fa-times fa-sm' />
            </IconButton>
          </div>
          <div className='column' style={{gap: 8}}>
            {data?.map(block => (
              <BlockItem key={block.name} block={block} />
            ))}
          </div>
        </div>
      </Paper>
    )
  }

  return (
    <Snackbar
      open={popup.open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handlePopupClose}
    >
      <Alert style={{display: 'flex', alignItems: 'center'}} onClose={handlePopupClose} severity={popup.severity}>
        <Typography variant={'h5'}>{popup.message}</Typography>
      </Alert>
    </Snackbar>
  )
}

export default BlocksTable

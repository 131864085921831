import React, { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import { awsLogout, redirectToCognitoPage } from '../../../../api/auth-api'
import { getIsAuth } from '../../../../storage/user-storage'

const AuthButton = () => {
  const [isFetching, setIsFetching] = useState(false)
  const isAuth = getIsAuth()

  const handleLogout = () => {
    awsLogout()
    // setError('')

    // const logout = async () => {
    //   try {
    //     setIsFetching(true)
    //     await function() {} //logout request

    //     localStorage.setItem(
    //       'user',
    //       JSON.stringify({
    //         accessToken: '',
    //         email: '',
    //         password: '',
    //         isAuth: false,
    //       })
    //     )
    //     setIsFetching(false)
    //     history.push('/login')
    //   } catch (error) {
    //     setIsFetching(false)
    //     setError(`Error: ${error.message}`)
    //   }
    // }
    // logout()
  }

  return (
    <>
      {/* <Snackbar
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => {}}
        message={error?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      /> */}
      <Button onClick={isAuth ? handleLogout : () => redirectToCognitoPage()} disabled={isFetching}>
        {isAuth ? 'Log out' : 'Log in'}
        {isFetching && <CircularProgress size={18} style={{ marginLeft: 8 }} />}
      </Button>
    </>
  )
}

export default AuthButton

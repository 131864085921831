import React, { useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './App.css'
import Layout from './components/Layout/Layout'
import Login from './components/Login/Login'
import Preloader from './components/Preloader/Preloader'
import withAuthRedirect from './hoc/withAuthRedirect'
import withSuspense from './hoc/withSuspense'
import { useInit } from './hooks/use-init'
import Bot from './pages/Bot/Bot'
import AwsCallback from './pages/AwsCallback/AwsCallback'
import Settings from './pages/Settings/Settings'
import TopDeals from './pages/TopDeals/TopDeals'
import { store } from './redux/store'
import { initializeApp } from './redux/thunks/initialize-app'
import BotHistory from './pages/BotHistory/BotHistory'
import BotSignals from './pages/BotSignals/BotSignals'
import BotOpportunities from './pages/BotOpportunities/BotOpportunities'
import Bookkeeping from './pages/Bookkeeping/Bookkeeping'

const BrokersTable = React.lazy(() => import('./components/BrokersTable/BrokersTable'))
const SuspendedBrokersTable = withSuspense(BrokersTable)

const PrivateSettings = withAuthRedirect(Settings)
const PrivateBrokersTable = withAuthRedirect(SuspendedBrokersTable)
const PrivateBotPage = withAuthRedirect(Bot)
const PrivateTopDeals = withAuthRedirect(TopDeals)
const PrivateBotHistory = withAuthRedirect(BotHistory)
const PrivateBotSignals = withAuthRedirect(BotSignals)
const PrivateBotOpportunities = withAuthRedirect(BotOpportunities)

function App() {
  const initialized = useSelector((state) => state.settings.initialized)
  const { isLoading, error } = useInit()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initializeApp())
  }, [])

  if (!initialized || isLoading)
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <Preloader />
      </div>
    )

  if (error)
    return (
      <h3 style={{ color: 'red', padding: 15 }}>
        {error} <br /> Check console for more details
      </h3>
    )

  return (
    <Layout>
      <Switch>
        <Route exact path={'/'}>
          <PrivateBotPage />
          {/* <PrivateHomePage /> */}
        </Route>
        <Route path={'/settings'}>
          <PrivateSettings />
        </Route>
        <Route path={'/login'}>
          <Login />
        </Route>
        <Route path={'/brokers'}>
          <PrivateBrokersTable />
        </Route>
        {/* <Route path={'/balance'}>
          <PrivateBotPage />
        </Route> */}
        <Route path={'/top-deals'}>
          <PrivateTopDeals />
        </Route>
        <Route path={'/bot-history/:botId'}>
          <PrivateBotHistory />
        </Route>
        <Route path={'/bot-signals'}>
          <PrivateBotSignals />
        </Route>
        <Route path={'/bot-opportunities'}>
          <PrivateBotOpportunities />
        </Route>
        <Route path={'/callback'}>
          <AwsCallback />
        </Route>
        <Route path={'/token-callback'}>
          <AwsCallback />
        </Route>
        <Route path={'/bookkeeping'}>
          <Bookkeeping></Bookkeeping>
        </Route>
        <Route path={'*'}>
          <h1>404. Page not found.</h1>
        </Route>
      </Switch>
    </Layout>
  )
}

class AppWrap extends React.Component {
  render() {
    return (
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    )
  }
}

export default AppWrap

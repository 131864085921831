import Table from '../../../../components/Table/Table'
import { Button, Paper, Typography } from '@material-ui/core'
import { useEffect, useMemo, useState, useCallback } from 'react'
import botAPI from '../../../../api/bot-api'
import Preloader from '../../../../components/Preloader/Preloader'
import { COLUMNS } from './columns'
import s from '../../BotSignals.module.css'

const HistoryTable = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)
  const [sortBy, setSortBy] = useState(null)
  const [filters, setFilters] = useState(null)

  const fetchData = useCallback(async () => {
    const response = await botAPI.getHistory(page < 1 ? 1 : page, sortBy, filters)
    if (response?.History?.length > 0) {
      setData(response.History)
      setLastPage(response.totalRecords / 100)
    }
  }, [page, sortBy, filters])

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      fetchData()
      setIsFetching(false)
    })()
  }, [fetchData])

  const handleSortChange = (sortBy, filters) => {
    setSortBy(sortBy[0])
    setFilters(filters[0])
  }

  if (isFetching) return <Preloader />

  return (
    <Paper className={s.paper} elevation={2}>
      <Typography variant={'h5'} style={{ padding: '0 10px' }}>
        Bots history
      </Typography>
      <Table
        columns={columns}
        data={data}
        handleSortChange={handleSortChange}
        enableFilters
        manualSortBy
        manualFilters
      />
      <div className={s.pagination}>
        <Button onClick={() => setPage((prev) => prev - 1)} disabled={!page || page <= 1}>
          Prev
        </Button>
        <Button onClick={() => setPage((prev) => (prev ? prev + 1 : 2))} disabled={page >= lastPage}>
          Next
        </Button>
      </div>
    </Paper>
  )
}

export default HistoryTable

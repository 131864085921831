import { settingsActions } from '../reducers/settings-reducer'
import arbitrageAPI from '../../api/arbitrage-api'
import { getIsAuth } from '../../storage/user-storage'

export const initializeApp = () => async (dispatch) => {
  if (getIsAuth()) {
    const [savedExchanges, savedPairs, exchanges] = await Promise.all([
      arbitrageAPI.getSavedExchanges(),
      arbitrageAPI.getSavedPairs(),
      arbitrageAPI.getExchanges(),
    ])
    dispatch(settingsActions.setSettings(savedExchanges, savedPairs))
    if (exchanges) dispatch(settingsActions.setAvailableExchanges(exchanges))
  }

  dispatch(settingsActions.setInitialized(true))
}

import { instance } from './axios'

const blocksApi = {
  getBlocks: () => {
    return instance
      .get(`/block-status`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  blockExecution: (name, exchange) => {
    return instance
      .post(`/block-execution`, { name, exchange })
      .then((res) => res)
      .catch((err) => console.log(err))
  },
  unblockExecution: (name, exchange) => {
    return instance
      .post(`/unblock-execution`, { name, exchange })
      .then((res) => res)
      .catch((err) => console.log(err))
  },
}

export default blocksApi

import { Paper, Table as MuiTable, TableBody, TableContainer, TableHead, Typography } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React, { useEffect, useMemo, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import balancesApi from '../../api/balances-api.js'
import Preloader from '../Preloader/Preloader'
import { getColumns } from './columns'
import s from './ReservesTable.module.css'

export const UNARCHIVED = 'unarchived'
export const ARCHIVED = 'archived'
export const CEX = 'cexAssets'
export const DEX = 'dexAssets'

const ReservesTable = () => {
  const [data, setData] = useState([])
  const [pairs, setPairs] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const columns = useMemo(() => getColumns(pairs), [pairs])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      initialState: { sortBy: [{ id: 'Broker', desc: false }] },
    },
    useSortBy,
  )

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      const data = await balancesApi.getBalance()
      if (data) {
        const tableData = []
        const pairs = [...new Set([...data[CEX], ...data[DEX]].map((value) => value.name))]
        console.log(pairs)
        Object.keys(data).forEach((exchangeType) => {
          return [
            ...new Set(data[exchangeType].map((value) => (exchangeType === DEX ? value.network : value.exchange))),
          ].forEach((broker) => {
            const balance = {}
            data[exchangeType].forEach((value) => {
              if (value[exchangeType === DEX ? 'network' : 'exchange'] === broker) {
                balance[value.name] = { balance: value.balance, reserved: value.reserved }
              }
            })

            tableData.push({
              exchangeType,
              name: broker,
              values: balance,
            })
          })
        })
        setData(tableData)
        setPairs(pairs)
        setIsFetching(false)
      }
    })()
  }, [])

  if (isFetching) return <Preloader />

  return (
    <Paper className={s.paper + ' flex-column'} elevation={2}>
      <div className={s.heading}>
        <Typography variant={'h5'}>Balances</Typography>
      </div>
      <TableContainer className={s.table}>
        {rows.length > 0 && (
          <MuiTable stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <div className={s.tableHeadCell}>
                          <span
                            style={{
                              userSelect: 'none',
                            }}
                          >
                            {column.render('Header')}
                          </span>
                          <i
                            style={{
                              fontSize: '11px',
                              marginLeft: '2px',
                              color: !column.isSorted && 'transparent',
                            }}
                            className={`fas fa-chevron-${
                              column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'
                            }`}
                          />
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <TableCell {...cell.getCellProps()}>{cell.render('Cell', {})}</TableCell>
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        )}
        {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
      </TableContainer>
    </Paper>
  )
}

export default ReservesTable

import { Backdrop, Paper, Fade, Modal, TextField, Button, Snackbar, Alert } from '@material-ui/core'
import { useState } from 'react'
import vaultAPI from '../../../../api/vault-api'
import s from './AccessInput.module.css'

const initialKeys = {
  publicKey: '',
  privateKey: '',
  additionalPassword: '',
  token: '',
}

const AccessInput = ({ vault: { show, id, name }, onClose }) => {
  const [isSaving, setIsSaving] = useState(false)
  const [values, setValues] = useState(initialKeys)
  const [popup, setPopup] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const handleChange = (e) => {
    const key = e.target.name
    const value = e.target.value
    setValues((prev) => ({ ...prev, [key]: value }))
  }

  const saveKeys = async function () {
    setIsSaving(true)

    try {
      const response = await vaultAPI.updateExchangeKeys(id, values)

      if (response === 'success') {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'Keys saved successfully!',
          severity: 'success',
        }))
        onClose()
        setValues(initialKeys)
      } else {
        throw Error(response?.data.id[0])
      }
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: `Error. ${err}`, severity: 'error' }))
    } finally {
      setIsSaving(false)
    }
  }

  const handlePopupClose = () => setPopup((prev) => ({ ...prev, open: false }))

  return (
    <>
      <Modal
        aria-labelledby='modal-graph'
        aria-describedby='modal-graph'
        className={s.modal}
        open={show}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Paper className={s.vault}>
            <h4>{name}</h4>
            <div className='column' style={{ width: '100%' }}>
              <TextField
                name='privateKey'
                value={values.privateKey}
                onChange={handleChange}
                fullWidth
                margin='normal'
                size='small'
                label='Private key'
              />
              <TextField
                name='publicKey'
                value={values.publicKey}
                onChange={handleChange}
                fullWidth
                margin='normal'
                size='small'
                label='Public key'
              />
              <TextField
                name='additionalPassword'
                value={values.additionalPassword}
                onChange={handleChange}
                fullWidth
                margin='normal'
                size='small'
                label='Additional password'
              />
              <TextField
                name='token'
                value={values.token}
                onChange={handleChange}
                fullWidth
                margin='normal'
                size='small'
                label='Token'
              />
            </div>
            <div className={s.saveBtn}>
              <Button disabled={isSaving} onClick={saveKeys} variant={'outlined'} color='inherit'>
                Save
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
      <Snackbar
        open={popup.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handlePopupClose}
      >
        <Alert onClose={handlePopupClose} severity={popup.severity}>
          {popup.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AccessInput

import React from 'react'
import { NavLink } from 'react-router-dom'
import s from './Header.module.css'

const Nav = () => {
  return (
    <>
      {/* <NavLink className={s.navItem} activeClassName={s.activeNavItem} exact to={'/'}>
        Home
      </NavLink> */}
      {/* <NavLink className={s.navItem} activeClassName={s.activeNavItem} to={'/brokers'}>
        Brokers
      </NavLink> */}
      <NavLink className={s.navItem} activeClassName={s.activeNavItem} exact to={'/'}>
        Bot
      </NavLink>
      {/* <NavLink className={s.navItem} activeClassName={s.activeNavItem} to={'/top-deals'}>
        Top Deals
      </NavLink> */}
      <NavLink className={s.navItem} activeClassName={s.activeNavItem} to={'/bot-signals'}>
        Signals
      </NavLink>
      <NavLink className={s.navItem} activeClassName={s.activeNavItem} to={'/bot-opportunities'}>
        Opportunities
      </NavLink>
      {/* <NavLink className={s.navItem} activeClassName={s.activeNavItem} to={'/settings'}>
        Settings
      </NavLink> */}
    </>
  )
}

export default Nav

import { useEffect, useMemo, useRef, useState } from 'react'
import s from './Graph.module.css'
import { CHART_RESPONSE } from '../../data/graph-data'
import { buildGraphData } from '../../utilities/number-helpers'
import { Line } from 'react-chartjs-2'
import { FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import arbitrageAPI from '../../api/arbitrage-api'
import Preloader from '../Preloader/Preloader'

const THREE = 3
const FIVE = 5
const SEVEN = 7

const options = {
  responsive: true,
  maintainAspectRatio: false,
}

const Graph = ({ current, handleClose }) => {
  const chartRef = useRef(null)
  const [data, setData] = useState([])
  const [key, setKey] = useState(0)
  const [period, setPeriod] = useState(SEVEN)
  const [isLoading, setIsLoading] = useState(false)

  const { directLine, possibleLine } = useMemo(() => buildGraphData(data), [data])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      const data = await arbitrageAPI.getChartData(current.pair, current.bid, current.ask)
      setData(data)

      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    setKey((key) => key + 1)
  }, [directLine, possibleLine])

  const handlePeriodChange = (e) => setPeriod(e.target.value)

  if (isLoading)
    return (
      <div style={{ padding: 20 }}>
        <Preloader />
      </div>
    )
  if (!data || data?.length < 1) return <h2 style={{ textAlign: 'center' }}>No data</h2>

  return (
    <div className='flex-column'>
      <IconButton size='small' className={s.closeButton} onClick={handleClose}>
        <i className='fas fa-times' />
      </IconButton>
      <Typography variant='h4'>{current.pair}</Typography>
      <Typography variant='h5'>
        {current.bid} - {current.ask}
      </Typography>
      <FormControl variant='outlined' size='small' sx={{ m: 1, minWidth: 120 }}>
        {/* <InputLabel>Days</InputLabel>
                <Select
                    disabled
                    value={period}
                    onChange={handlePeriodChange}
                    label="For Period"
                >
                    <MenuItem value={THREE}>Three days</MenuItem>
                    <MenuItem value={FIVE}>Five days</MenuItem>
                    <MenuItem value={SEVEN}>Seven days</MenuItem>
                </Select>*/}
      </FormControl>
      <div style={{ position: 'relative', height: 380, width: '100%' }}>
        <Line
          key={key}
          ref={chartRef}
          data={{
            datasets: [
              {
                label: 'Direct, %',
                data: directLine,
                pointHitRadius: 3.5,
                pointRadius: 0,
                borderColor: 'rgb(81, 81, 255)',
                backgroundColor: 'rgba(81, 81, 255, .35)',
                borderWidth: 1.5,
              },
              {
                label: 'Possible, %',
                data: possibleLine,
                pointHitRadius: 3,
                pointRadius: 0,
                borderColor: 'black',
                borderWidth: 1.5,
              },
            ],
          }}
          options={options}
          redraw={false}
        />
      </div>
    </div>
  )
}

export default Graph

import { TableHead as Head, TableCell, TableRow } from '@material-ui/core'

const TableHeader = ({ headerGroups }) => {
  return (
    <Head>
      {headerGroups.map((headerGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row !important',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span style={{ userSelect: 'none' }}>{column.render('Header')}</span>
                <i
                  style={{
                    fontSize: '11px',
                    marginLeft: '2px',
                    color: !column.isSorted && 'transparent',
                  }}
                  className={`fas fa-chevron-${column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'}`}
                />
              </div>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Head>
  )
}

export default TableHeader

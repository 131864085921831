import React from 'react'
import Container from '@material-ui/core/Container'
import { useLocation } from 'react-router'
import Header from './Header/Header'

const Layout = ({ children }) => {
  const location = useLocation()

  return (
    <Container maxWidth='false' className='app'>
      {location.pathname !== '/login' && <Header />}
      <div className={'content'}>{children}</div>
    </Container>
  )
}

export default Layout

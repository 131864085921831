import { useEffect, useState } from 'react'
import botAPI from '../../api/bot-api'
import ReservesTable from '../../components/ReservesTable/ReservesTable'
import BookkeepingTable from '../../components/BookkeepingTable/BookkeepingTable'
import BotTable, { ARCHIVED, UNARCHIVED } from '../../components/BotTable/BotTable'
import BlocksTable from '../../components/BlocksTable/BlocksTable'

const Bot = () => {
  const [bots, setBots] = useState([])
  const [archivedBots, setArchivedBots] = useState([])
  const [botsAreFetching, setBotsAreFetching] = useState(false)

  const requestBots = async () => {
    let [bots, archivedBots] = await Promise.all([botAPI.getBotList(), botAPI.getArchivedBotList()])
    if (bots?.length > 0) setBots(bots)
    if (archivedBots?.length > 0) setArchivedBots(archivedBots)
  }

  useEffect(() => {
    ;(async () => {
      setBotsAreFetching(true)
      await requestBots()
      setBotsAreFetching(false)
    })()
  }, [])

  return (
    <>
      <BlocksTable />
      <ReservesTable />
      <BotTable
        botsType={UNARCHIVED}
        data={bots}
        setData={setBots}
        isFetching={botsAreFetching}
        refreshBots={requestBots}
      />
      <BookkeepingTable />
      <BotTable
        botsType={ARCHIVED}
        data={archivedBots}
        setData={setArchivedBots}
        isFetching={botsAreFetching}
        refreshBots={requestBots}
      />
    </>
  )
}

export default Bot

import {
  Button,
  Paper,
  Popover,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import botAPI from '../../api/bot-api'
import { COLUMNS } from './columns'
import s from './BotHistory.module.css'
import { useHistory, useParams } from 'react-router'
import { useQuery } from '../../hooks/use-query'
import Preloader from '../../components/Preloader/Preloader'

const BotHistory = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [lastPage, setLastPage] = useState(1)
  const [fillsAnchor, setFillsAnchor] = useState(null)
  const [fillsData, setFillsData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const { botId } = useParams()

  const history = useHistory()
  const query = useQuery()
  const page = Number(query?.get('page')) || 1

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
    },
    useSortBy,
  )

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      const response = await botAPI.getBotHistory(botId, page < 1 ? 1 : page)
      if (response?.data?.length > 0) {
        setData(response.data)
        setLastPage(response.meta?.last_page)
      }
      setIsFetching(false)
    })()
  }, [page, botId])

  const openFills = (e, fills) => {
    setFillsData(fills)
    setFillsAnchor(e.currentTarget)
  }

  const closeFills = () => setFillsAnchor(null)

  const showFills = Boolean(fillsAnchor)

  if (isFetching) return <Preloader />

  return (
    <Paper className={s.paper} elevation={2}>
      <Typography variant={'h5'} style={{ padding: '0 10px' }}>
        Bot #{botId} history
      </Typography>
      <TableContainer className={s.table}>
        {rows.length > 0 && (
          <MuiTable stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        style={{
                          display: 'flex !important',
                        }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <span
                          style={{
                            userSelect: 'none',
                          }}
                        >
                          {column.render('Header')}
                        </span>
                        <i
                          style={{
                            fontSize: '11px',
                            marginLeft: '2px',
                            color: !column.isSorted && 'transparent',
                          }}
                          className={`fas fa-chevron-${column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'}`}
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>{cell.render('Cell', { openFills, closeFills })}</TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        )}
        {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
      </TableContainer>
      <div className={s.pagination}>
        <Button onClick={() => history.push(`/bot-history/${botId}?page=${page - 1}`)} disabled={!page || page <= 1}>
          Prev
        </Button>
        <Button
          onClick={() => history.push(`/bot-history/${botId}?page=${page ? page + 1 : 2}`)}
          disabled={page >= lastPage}
        >
          Next
        </Button>
      </div>
      <Popover
        open={showFills}
        anchorEl={fillsAnchor}
        onClose={closeFills}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ pointerEvents: 'none' }}
        disableRestoreFocus
        useLayerForClickAway={false}
      >
        <div style={{ padding: 6, width: 220 }}>
          {fillsData?.map((fill, i) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div>{i}</div>
              <div>{fill[i]}</div>
            </div>
          ))}
        </div>
      </Popover>
    </Paper>
  )
}

export default BotHistory

import { useCallback, useEffect, useState, useMemo } from 'react'
import { useSortBy, useTable } from 'react-table'
import {
  Container,
  Button,
  Paper,
  Table as MuiTable,
  TableContainer,
  Typography,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core'
import Preloader from '../../components/Preloader/Preloader'
import { COLUMNS } from './columns'
import s from './BookkeepingTable.module.css'

// GetBookkeepingAPI
import bookkeepingApi from '../../api/bookkeeping-api'
import moment from 'moment'

const BookkeepingTable = () => {
  let last20Transactions = true
  const columns = useMemo(() => COLUMNS, [])
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState([])
  const [profit, setProfit] = useState([])

  const fetchData = useCallback(async () => {
    const response = await bookkeepingApi.getBookkeeping(1, 20)
    if (response?.records?.length > 0) {
      setData(response.records)
      setProfit(response.profit)
    }
  }, [])

  const DEFAULT_DAYS_AGO = 1

  const fetchToday = useCallback(async () => {
    const startTime = moment().utc().format('YYYY-MM-DD[T]00:00')
    const endTime = moment().utc().format('YYYY-MM-DD[T]23:59')
    const response = await bookkeepingApi.getBookkeepingByPeriod(
      moment.utc(startTime).toISOString(),
      moment.utc(endTime).toISOString(),
    )
    if (response?.records?.length > 0) {
      setData(response.records)
      setProfit(response.profit)
    }
  }, [])

  const fetchYesterday = useCallback(async () => {
    const startTime = moment().utc().subtract(1, 'days').format('YYYY-MM-DD[T]00:00')
    const endTime = moment().utc().subtract(1, 'days').format('YYYY-MM-DD[T]23:59')
    const response = await bookkeepingApi.getBookkeepingByPeriod(
      moment.utc(startTime).toISOString(),
      moment.utc(endTime).toISOString(),
    )
    if (response?.records?.length > 0) {
      setData(response.records)
      setProfit(response.profit)
    }
  }, [])

  const handleViewToday = useCallback(async () => {
    console.log('view today')
    setIsFetching(true)
    fetchToday()
    setIsFetching(false)
  }, [])

  const handleViewYesterday = useCallback(async () => {
    console.log('view yesterday')
    setIsFetching(true)
    fetchYesterday()
    setIsFetching(false)
  }, [])

  const handleViewLast = useCallback(async () => {
    console.log('view last')
    setIsFetching(true)
    fetchData()
    setIsFetching(false)
  }, [])

  // handleViewLast

  useEffect(() => {
    ;(async () => {
      setIsFetching(true)
      fetchData()
      setIsFetching(false)
    })()
  }, [fetchData])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
    },
    useSortBy,
  )

  if (isFetching) return <Preloader />
  return (
    <Container fixed maxWidth='xl'>
      <Paper className={s.paper + ' flex-column'} elevation={2}>
        <div className={s.heading}>
          <Typography variant={'h5'} style={{ padding: '0 10px' }}>
            Transactions
            <Button variant='text' onClick={handleViewToday}>
              Today
            </Button>
            <Button variant='text' onClick={handleViewYesterday}>
              Yesterday
            </Button>
            <Button variant='text' onClick={handleViewLast}>
              Last 20
            </Button>
            <Button variant='text' href='/bookkeeping'>
              Detailed view
            </Button>
          </Typography>

          <div className={s.profit}>Profit: {(profit * 1).toFixed(2)}</div>
        </div>
        <TableContainer className={s.table}>
          {rows.length > 0 && (
            <MuiTable stickyHeader {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                          <div className={s.tableHeadCell}>{column.render('Header')}</div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <TableCell {...cell.getCellProps()}>{cell.render('Cell', {})}</TableCell>
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </MuiTable>
          )}
          {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
        </TableContainer>
      </Paper>
    </Container>
  )
}

export default BookkeepingTable

import moment from 'moment'

export const COLUMNS = [
  {
    Header: 'Bot ID',
    accessor: 'requestId',
    Cell: ({ value, row }) => (
      <a
        href={`https://polygonscan.com/tx/${row.original.transaction?.dexResponse?.txHash}`}
        target={'_blank'}
        rel='noreferrer'
      >
        <span> {value.substring(0, value.indexOf('_'))}</span>
      </a>
    ),
  },
  {
    Header: 'Time',
    accessor: 'transaction.createdAt',
    Cell: ({ value }) => (
      <span>{moment(value, 'YYYY-MM-DD HH:mm:ss.SSSSS +0000 +0000').format('YYYY-MM-DD HH:mm')}</span>
    ),
  },
  {
    Header: 'Pair',
    accessor: 'transaction.requestData.dexSymbolLeft',
    Cell: ({ row }) => (
      <span>
        {row.original.transaction.requestData.dexSymbolLeft}
        {row.original.transaction.requestData.dexSymbolRight}
      </span>
    ),
  },
  {
    Header: 'Direction',
    accessor: 'transaction.requestData.direction',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Sum bought',
    accessor: 'usdtSpent',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
  },
  {
    Header: 'Dex fee',
    accessor: 'gas',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
  },
  {
    Header: 'Cex fee',
    accessor: 'cexFee',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
  },
  {
    Header: 'Sum sold',
    accessor: 'usdtReceived',
    Cell: ({ value }) => (value ? <span>{(value * 1).toFixed(2)}</span> : ''),
  },
  {
    Header: 'Profit',
    accessor: 'total',
    Cell: ({ value }) =>
      value < 0 ? (
        <span style={{ color: 'red' }}>{(value * 1).toFixed(2)}</span>
      ) : (
        <span style={{ color: 'green' }}>{(value * 1).toFixed(2)}</span>
      ),
  },
  {
    Header: 'Profit Raw',
    Cell: ({ row }) =>
      // row.original?.total ? <span>{(row.original.total*1).toFixed(5)}</span> : ""
      Number(row.original.usdtSpent) && Number(row.original.total) ? (
        <span>{((row.original.total / row.original.usdtSpent) * 100).toFixed(3)}%</span>
      ) : (
        '-'
      ),
    disableFilters: true,
  },
]

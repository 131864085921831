import { useMediaQuery } from '@material-ui/core'
import { useEffect, useState } from 'react'
import AuthButton from './AuthButton/AuthButton'
import BurgerButton from './BurgerButton/BurgerButton'
import s from './Header.module.css'
import Nav from './Nav'

const Header = () => {
  const isMobile = useMediaQuery('(max-width: 1000px)')
  const [menuOpened, setMenuOpened] = useState(!isMobile)

  useEffect(() => {
    setMenuOpened(!isMobile)
  }, [isMobile])

  const name = localStorage.getItem('username') || 'Sign In'

  return (
    <>
      <header className={s.header}>
        <div className='flex-row align-center'>
          <i className={`${s.logo} fab fa-react fa-2x`} />
          <div className={s.burgerBtn}>
            {/*<i className="fas fa-bars"/>*/}
            <BurgerButton opened={menuOpened} onClick={() => setMenuOpened(!menuOpened)} />
          </div>
          <Nav />
        </div>
        <div className='flex-row align-center'>
          <span className={s.greeting}>
            {`Welcome home, `}
            <strong>{name}</strong>
          </span>
          <AuthButton />
        </div>
      </header>
      <section
        style={{
          maxHeight: menuOpened ? (isMobile ? '100vh' : 80) : 0,
          height: !isMobile ? 65 : null,
        }}
      >
        <Nav />
      </section>
    </>
  )
}

export default Header

import React, { useState } from 'react'
import s from './Login.module.css'
import { Formik } from 'formik'
import { Button, Card, CircularProgress, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { validateLogin } from '../../utilities/validators'
import Typography from '@material-ui/core/Typography'
import { Redirect, useHistory } from 'react-router'
import { getIsAuth } from '../../storage/user-storage'

const Login = () => {
  const [showPass, setShowPass] = useState(false)
  const [error, setError] = useState(null)
  const isAuth = getIsAuth()

  const history = useHistory()

  const handleSubmit = (values, { setSubmitting }) => {
    // setSubmitting(true);
    // setError(null);
    // const login = async () => {
    //     try {
    //         const data = await authAPI.login(values.email, values.password)
    //         saveUser(data.access_token, values.email, data.user.name, true);
    //         history.push('/')
    //     } catch (error) {
    //         setError(`Error: ${error.message}`);
    //         setSubmitting(false);
    //     }
    // };
    // login();
  }

  if (isAuth) return <Redirect to={'/'} />

  return (
    <Card elevation={4} style={{ borderRadius: 10 }}>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => validateLogin(values)}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={s.form}>
            <Typography variant={'h5'} style={{ marginBottom: '12px', fontWeight: 400 }}>
              Sign In
            </Typography>
            <TextField
              className={s.input}
              type='email'
              name='email'
              label='Email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              variant={'outlined'}
              size={'small'}
              error={!!(errors.email && touched.email)}
              helperText={errors.email && touched.email && errors.email}
            />
            <TextField
              className={s.input}
              type={showPass ? 'text' : 'password'}
              name='password'
              label='Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              variant={'outlined'}
              size={'small'}
              error={!!(errors.password && touched.password)}
              helperText={errors.password && touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPass((prev) => !prev)}
                      size={'small'}
                    >
                      {showPass ? <i className='fas fa-eye' /> : <i className='fas fa-eye-slash' />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type='submit' onClick={handleSubmit} disabled={isSubmitting} variant={'outlined'}>
              Log In
              {isSubmitting && <CircularProgress size={18} style={{ marginLeft: 8 }} />}
            </Button>
            {error && <h5 style={{ color: 'red' }}>{error}</h5>}
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default Login

import { Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useCallback } from 'react'
import { useSortBy, useFilters, useTable } from 'react-table'
import s from './Table.module.css'

const Table = ({
  columns,
  data,
  enableFilters = false,
  handleSortChange = () => {},
  manualSortBy = false,
  manualFilters = false,
  cellProps,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      stateReducer: (newState) => handleSortChange(newState.sortBy, newState.filters),
      disableMultiSort: true,
      autoResetSortBy: false,
      manualSortBy,
      manualFilters,
    },
    useFilters,
    useSortBy,
  )

  const FiltersRow = useCallback(
    ({ headers }) => (
      <TableRow className={s.filters}>
        {headers.map((column, i) => (
          <TableCell key={i} {...column.getHeaderProps()}>
            {column.canFilter ? column.render('Filter') : null}
          </TableCell>
        ))}
      </TableRow>
    ),
    [],
  )

  return (
    <TableContainer className={s.table}>
      <MuiTable stickyHeader {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, i) => (
            <>
              <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  return (
                    <TableCell
                      key={i}
                      style={{
                        display: 'flex !important',
                      }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className='flex-row align-center' style={{ gap: 4 }}>
                        <span
                          style={{
                            userSelect: 'none',
                          }}
                        >
                          {column.render('Header')}
                        </span>
                        <i
                          style={{
                            fontSize: '11px',
                            marginLeft: '2px',
                            color: !column.isSorted && 'transparent',
                          }}
                          className={`fas fa-chevron-${column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'}`}
                        />
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
              {enableFilters && <FiltersRow headers={headerGroup.headers} key='Filters' />}
            </>
          ))}
        </TableHead>
        {rows.length > 0 && (
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell key={i} {...cell.getCellProps()}>
                        {cell.render('Cell', { ...cellProps })}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </MuiTable>
      {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
    </TableContainer>
  )
}

export default Table

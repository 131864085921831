import { instance } from './axios'

const balancesApi = {
  getBalances: () => {
    return instance
      .get(`/get-balances`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getBalance: () => {
    return instance
      .get(`/balance`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
}

export default balancesApi

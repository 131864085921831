export function alphanumericFalsyLast(rowA, rowB, columnId, desc) {
  const a = rowA.values[columnId]
  const b = rowB.values[columnId]
  if (!a && !b) {
    return 0
  }
  if (!a) {
    return desc ? -1 : 1
  }
  if (!b) {
    return desc ? 1 : -1
  }
  return a - b
}

import { Paper, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import vaultAPI from '../../../api/vault-api'
import Preloader from '../../../components/Preloader/Preloader'
import TableHeader from '../../../components/TableHeader/TableHeader'
import AccessInput from './AccessInput/AccessInput'
import s from './CexManager.module.css'
import { COLUMNS } from './columns'

const CexManager = () => {
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [vault, setVault] = useState({ name: '', id: null, show: false })

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      const data = await vaultAPI.getVaultExchanges()
      if (data) setData(data)

      setIsLoading(false)
    })()
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
    },
    useSortBy,
  )

  const onSettings = (name, id) => setVault((vault) => ({ ...vault, name, id, show: true }))

  if (isLoading) return <Preloader />

  return (
    <>
      <Paper className={'flex-column'} elevation={2}>
        <TableContainer className={s.table}>
          {rows.length > 0 && (
            <MuiTable stickyHeader {...getTableProps()}>
              <TableHeader headerGroups={headerGroups} />
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row)
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell style={{ width: 140 }} {...cell.getCellProps()}>
                            {cell.render('Cell', { onSettings })}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </MuiTable>
          )}
          {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
        </TableContainer>
      </Paper>
      <AccessInput vault={vault} onClose={() => setVault({ ...vault, show: false })} />
    </>
  )
}

export default CexManager

import { Button, Paper } from '@material-ui/core'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import arbitrageAPI from '../../../api/arbitrage-api'
import topDealsAPI from '../../../api/top-deals-api'
import BackButton from '../../../components/BackButton/BackButton'
import useDebounce from '../../../hooks/use-debounce'
import Preloader from '../../../components/Preloader/Preloader'

const TopDealsSettings = () => {
  const [pairs, setPairs] = useState([])
  const [pairQuery, setPairQuery] = useState('')
  const [isPairFetching, setIsPairFetching] = useState(false)
  const [pairsOptions, setPairsOptions] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  const debouncedPairQuery = useDebounce(pairQuery, 400)

  useEffect(() => {
    async function fetchSavedPairs() {
      setIsFetching(true)

      const savedPairs = await topDealsAPI.getSavedPairs()
      setPairs(savedPairs)

      setIsFetching(false)
    }
    fetchSavedPairs()
  }, [])

  useEffect(() => {
    if (!debouncedPairQuery) {
      return
    }

    ;(async () => {
      setIsPairFetching(true)
      const data = await arbitrageAPI.getPairs(debouncedPairQuery, true)
      setPairsOptions(data?.data)
      setIsPairFetching(false)
    })()
  }, [debouncedPairQuery])

  const handlePairsChange = (value) => setPairs(value)

  const handlePairQueryChange = (value) => setPairQuery(value)

  const handleSave = async () => {
    setIsSaving(true)

    const response = await topDealsAPI.savePairs(pairs.map((p) => p.id))

    setIsSaving(false)
    alert('Setting saved successfully!')
  }

  if (isFetching) return <Preloader />

  return (
    <Paper className={'flex-column'} elevation={2} style={{ padding: 8 }}>
      <BackButton />
      <h4>Top Deals for pairs:</h4>
      <div className={'flex-row flex-wrap'}>
        <Select
          className={'input'}
          styles={{
            valueContainer: (base) => ({
              ...base,
              maxHeight: 200,
              overflow: 'auto',
            }),
          }}
          name='Pairs'
          options={pairsOptions}
          value={pairs}
          getOptionLabel={(x) => x.name}
          getOptionValue={(x) => x.id}
          onChange={handlePairsChange}
          onInputChange={handlePairQueryChange}
          isMulti
          cacheOptions
          defaultOptions
          placeholder={'Start typing...'}
          closeMenuOnSelect={false}
          isLoading={isPairFetching}
        />
      </div>
      <Button disabled={isSaving} onClick={handleSave} variant={'outlined'} color='inherit'>
        Save
      </Button>
    </Paper>
  )
}

export default TopDealsSettings

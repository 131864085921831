import { useEffect, useMemo, useRef, useState } from 'react'
import s from './BotGraph.module.css'
import { buildGraphLine } from '../../utilities/number-helpers'
import { Line } from 'react-chartjs-2'
import { FormControl, IconButton, InputLabel, Input, Typography, TextField, Button } from '@material-ui/core'
import Preloader from '../Preloader/Preloader'
import botAPI from '../../api/bot-api'
import moment from 'moment'

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    decimation: {
      enabled: true,
      algorithm: 'min-max',
    },
  },
}

const DEFAULT_DAYS_AGO = 1

const startTime = moment().subtract(DEFAULT_DAYS_AGO, 'days').format('YYYY-MM-DD[T]HH:mm')
const endTime = moment().format('YYYY-MM-DD[T]HH:mm')

const BotGraph = ({ botId, handleClose }) => {
  const chartRef = useRef(null)
  const [data, setData] = useState([])
  const [key, setKey] = useState(0)
  const [period, setPeriod] = useState({ startTime, endTime })
  const [isLoading, setIsLoading] = useState(false)

  const buildBotGraph = (points) => {
    const value = buildGraphLine(points, 'value')
    const profit = buildGraphLine(points, 'profit')
    return { value, profit }
  }

  const { value, profit } = useMemo(() => buildBotGraph(data?.points || []), [data])

  const fetchData = async () => {
    setIsLoading(true)

    const data = await botAPI.getChartData(
      botId,
      moment(period.startTime).toISOString(),
      moment(period.endTime).toISOString(),
    )
    setData(data)

    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [botId])

  useEffect(() => {
    setKey((key) => key + 1)
  }, [value])

  const handlePeriodChange = (e) => setPeriod((prev) => ({ ...prev, [e.target.id]: e.target.value }))

  if (isLoading)
    return (
      <div style={{ padding: 20 }}>
        <Preloader />
      </div>
    )

  return (
    <div className='flex-column'>
      <IconButton size='small' className={s.closeButton} onClick={handleClose}>
        <i className='fas fa-times' />
      </IconButton>
      <Typography variant='h4'>Bot ID: {botId}</Typography>
      <div className='flex-row align-center'>
        <FormControl variant='outlined' size='small' sx={{ m: 1, minWidth: 120 }}>
          <TextField
            id='startTime'
            label='Start time'
            type='datetime-local'
            value={period.startTime}
            defaultValue={period.startTime}
            onChange={handlePeriodChange}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        {' – '}
        <FormControl variant='outlined' size='small' sx={{ m: 1, minWidth: 120 }}>
          <TextField
            id='endTime'
            label='End time'
            type='datetime-local'
            value={period.endTime}
            defaultValue={period.endTime}
            onChange={handlePeriodChange}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <Button onClick={fetchData}>OK</Button>
      </div>
      {!data || value?.length < 1 ? (
        <h2 style={{ textAlign: 'center' }}>No data</h2>
      ) : (
        <div style={{ position: 'relative', height: 380, width: '100%' }}>
          <Line
            key={key}
            ref={chartRef}
            data={{
              datasets: [
                {
                  label: 'Value',
                  data: value,
                  pointHitRadius: 3.5,
                  pointRadius: 0,
                  borderColor: 'rgb(81, 81, 255)',
                  backgroundColor: 'rgba(81, 81, 255, .35)',
                  borderWidth: 1.5,
                },
                {
                  label: 'Profit',
                  data: profit,
                  pointHitRadius: 2.5,
                  pointRadius: 0,
                  borderColor: 'rgb(44, 255, 0)',
                  backgroundColor: 'rgba(44, 255, 0, .35)',
                  borderWidth: 2,
                },
              ],
            }}
            options={options}
            redraw={false}
          />
        </div>
      )}
    </div>
  )
}

export default BotGraph

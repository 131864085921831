import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router'
import { NavLink } from 'react-router-dom'
import CexManager from './CexManager/CexManager'
import HomeSettings from './HomeSettings/HomeSettings'
import s from './Settings.module.css'
import TopDealsSettings from './TopDealsSettings/TopDealsSettings'

const Settings = () => {
  const { path, url } = useRouteMatch()

  return (
    <div className='flex-column'>
      <div style={{ marginBottom: 20 }}>
        <NavLink className={s.navItem} activeClassName={s.activeNavItem} exact to={`${url}`}>
          Arbitrage
        </NavLink>
        <NavLink className={s.navItem} activeClassName={s.activeNavItem} to={`${url}/top-deals`}>
          Top Deals
        </NavLink>
        <NavLink className={s.navItem} activeClassName={s.activeNavItem} to={`${url}/api-vault`}>
          CEX Manager
        </NavLink>
      </div>
      <Switch>
        <Route exact path={`${path}`}>
          <HomeSettings />
        </Route>
        <Route path={`${path}/top-deals`}>
          <TopDealsSettings />
        </Route>
        <Route path={`${path}/api-vault`}>
          <CexManager />
        </Route>
      </Switch>
    </div>
  )
}

export default Settings

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Echo from 'laravel-echo'
import io from 'socket.io-client'
window.io = require('socket.io-client')

// window.Echo = new Echo({
//   broadcaster: 'socket.io',
//   host: process.env.REACT_APP_WS_BASE_URL,
//   client: io,
//   auth: {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}},
//   transports: ['websocket', 'polling'],
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

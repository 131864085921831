import { instance } from './axios'

const bookkeepingApi = {
  getBookkeeping: (page, page_size) => {
    return instance
      .get(`/bookkeeping`, {
        params: {
          page,
          page_size,
        },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getBookkeepingByPeriod: (startTime, endTime) => {
    return instance
      .get(`/bookkeeping-period`, {
        params: {
          start_time: startTime,
          end_time: endTime,
        },
      })

      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
}

export default bookkeepingApi

import { formatFloat } from '../../utilities/number-helpers'

export const COLUMNS = [
  {
    Header: 'Exchange type',
    id: 'exchangeType',
    accessor: 'exchangeType',
  },
  {
    Header: 'Asset',
    accessor: 'name',
  },
  {
    Header: 'Network',
    accessor: 'network',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Reserved',
    accessor: 'reserved',
  },
]

export const getColumns = (pairs) => {
  const customColumns = pairs.map((pair) => ({
    id: pair,
    Header: () => (
      <div className='flex-column' style={{ textAlign: 'center' }}>
        <strong>{pair}</strong>
        {/* <small>{pair.eur_rate}</small> */}
      </div>
    ),
    accessor: pair,
    Cell: ({ row }) => {
      const asset = row.original.values[pair]
      if (!asset || (!asset.balance && !asset.reserved)) return <strong className='red'>-</strong>
      return (
        <div className='flex-column'>
          <strong className='green'>{asset.balance && formatFloat(Number(asset.balance), 10)}</strong>
          <small className='red'>{asset.reserved}</small>
        </div>
      )
    },
    Footer: () => (
      <div className='flex-column'>
        <strong>pair.total</strong>
        {/* <small>{pair.eur_result} Eur</small> */}
      </div>
    ),
  }))

  customColumns.unshift({
    id: 'Broker',
    Header: () => (
      <div className='column'>
        <strong>Currency</strong>
        {/* <small>Eur rate</small> */}
      </div>
    ),
    accessor: 'name',
    Footer: () => (
      <div className='flex-column'>
        <strong>Total:</strong>
        {/* <small>
                    {pairs
                        .map((pair) => pair.eur_result)
                        .reduce((a, b) => a + b, 0)}{" "}
                    Eur
                </small> */}
      </div>
    ),
  })

  return customColumns
}

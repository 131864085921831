import React from 'react'
import s from './BurgerButton.module.css'

const BurgerButton = ({ opened, ...props }) => {
  return (
    <div className={s.burger} {...props} style={{ transition: 'all .3s linear' }}>
      <div style={{ transform: opened ? 'rotate(45deg)' : 'rotate(0)' }} />
      <div
        style={{
          opacity: opened ? 0 : 1,
          transform: opened ? 'translateX(100%)' : 'translateX(0)',
        }}
      />
      <div style={{ transform: opened ? 'rotate(-45deg)' : 'rotate(0)' }} />
    </div>
  )
}

export default BurgerButton

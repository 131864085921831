import { instance } from './axios'

const botAPI = {
  getBotList: () => {
    return instance
      .get(`/bot`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },
  saveBot: (botData) => {
    return instance.post(`/bot`, botData).then(
      (res) => res.data,
      (err) => err,
    )
  },
  changeBotStatus: (id, status) => {
    return instance.post(`/change-bot-status`, { id, status }).then(
      (res) => res.data,
      (err) => err,
    )
  },
  getArchivedBotList: () => {
    return instance
      .get(`/archived-bots`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },
  archiveBot: (id) => {
    return instance
      .post(`/archive-bot`, { id, status: 0, add_to_archive: 1 })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  unarchiveBot: (id) => {
    return instance
      .post(`/archive-bot`, { id, status: 0 })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getBotHistory: (id, page = 1) => {
    return instance
      .get(`/bot-history/${id}?page=${page}`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getSignals: (page) => {
    return instance
      .get(`/bot-signals?page=${page}`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getOpportunities: () => {
    return instance
      .get(`/bot-opportunities`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getHistory: (page, sortBy, filter) => {
    console.log(sortBy, filter)
    return instance
      .get(`/filtered-history`, {
        params: {
          page,
          sortedField: sortBy?.id,
          sortedDirection: sortBy?.desc == null ? undefined : sortBy?.desc ? 'desc' : 'asc',
          filteredField: filter?.id,
          filteredValue: filter?.value,
        },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
  getChartData: (botId, startTime, endTime) => {
    return instance
      .get(`/chart`, {
        params: {
          bot_id: botId,
          start_time: startTime,
          end_time: endTime,
        },
      })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },
}

export default botAPI

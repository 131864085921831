import { IconButton, Tooltip } from '@material-ui/core'
import { ARCHIVED, UNARCHIVED } from './BotTable'
import { Link } from 'react-router-dom'

export const COLUMNS = [
  {
    Header: 'Actions',
    id: 'actions',
    accessor: 'key',
    disableSortBy: true,
    Cell: ({ value, row, ...cell }) => {
      const isOn = row.original.status === 1

      return (
        <>
          {cell.botsType === UNARCHIVED && (
            <Tooltip title={`Press to ${isOn ? 'Deactivate' : 'Activate'}`}>
              <span>
                <IconButton
                  onClick={() => {
                    cell.switchBotStatus(row.original.id, isOn ? 0 : 1)
                  }}
                  size={'small'}
                  style={{ margin: '0 8px' }}
                  disabled={cell.isStatusChanging}
                >
                  <i className={`fas fa-power-off ${isOn ? 'red' : 'green'}`} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {cell.botsType === UNARCHIVED && (
            <Tooltip title={'Archive'}>
              <span>
                <IconButton
                  onClick={(e) => {
                    cell.openConfirmArchivation(e, row.original.id)
                  }}
                  size={'small'}
                  style={{ margin: '0 8px' }}
                  disabled={cell.botsType === ARCHIVED}
                >
                  <i className={`fas fa-archive blue`} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title='Edit'>
            <IconButton
              onClick={() => {
                cell.openBotEditor({ id: row.original.id })
              }}
              size={'small'}
              style={{ margin: '0 8px' }}
            >
              <i className='fas fa-pen' />
            </IconButton>
          </Tooltip>
        </>
      )
    },
  },
  {
    Header: 'Bot ID',
    accessor: 'id',
    Cell: ({ value }) => <Link to={`/bot-history/${value}`}>{value}</Link>,
  },
  {
    Header: 'CEX',
    accessor: 'cex_symbol_left',
    Cell: ({ value, row }) =>
      value && row.original.cex_symbol_right ? `${value}-${row.original.cex_symbol_right}` : 'Unknown',
  },
  {
    Header: 'DEX',
    accessor: 'dex_symbol_left',
    Cell: ({ value, row }) =>
      value && row.original.dex_symbol_right ? `${value}-${row.original.dex_symbol_right}` : 'Unknown',
  },
  {
    Header: 'From',
    accessor: 'exchange_from',
  },
  {
    Header: 'To',
    accessor: 'exchange_to',
  },
  {
    Header: 'PMin',
    accessor: 'pmin',
  },
  {
    Header: 'Direction',
    accessor: 'amount',
    Cell: ({ value, row, ...cell }) => (
      <div>
        {Object.keys(value).map((key) => (
          <div key={key}>{value[key].direction}</div>
        ))}
      </div>
    ),
  },
  {
    Header: 'Profit',
    accessor: 'profit',
    Cell: ({ row }) => (
      <div>
        {Object.keys(row.original.amount).map((key) => (
          <div key={key}>{row.original.amount[key].profit}</div>
        ))}
      </div>
    ),
  },
  {
    Header: 'Amount',
    accessor: 'qn',
    Cell: ({ row }) => (
      <div>
        {Object.keys(row.original.amount).map((key) => (
          <div key={key}>{row.original.amount[key].amount}</div>
        ))}
      </div>
    ),
  },
  {
    Header: 'Current arbitrage',
    accessor: 'opportunity',
    Cell: ({ row, value = {}, ...cell }) => (
      <div>
        {Object.keys(value).map((key) => (
          <div key={key}>
            {value[key].profit.toFixed(3)}
            <Tooltip title='Open chart'>
              <IconButton
                onClick={() => {
                  cell.openGraph(row.original.id, key)
                }}
                size={'small'}
                style={{ margin: '0 8px' }}
              >
                <i className='fas fa-chart-bar' />
              </IconButton>
            </Tooltip>
          </div>
        ))}
      </div>
    ),
  },
  {
    Header: 'DEX price',
    accessor: 'dexPrice',
    Cell: ({ row }) => (
      <div>
        {row.original.opportunity
          ? Object.keys(row.original.opportunity).map((key) => (
              <div key={key}>{row.original.opportunity[key].dexPrice.toFixed(4)}</div>
            ))
          : ''}
      </div>
    ),
  },
  {
    Header: 'DEX total',
    accessor: 'dexTotal',
    Cell: ({ row }) => (
      <div>
        {row.original.opportunity
          ? Object.keys(row.original.opportunity).map((key) => (
              <div key={key}>{row.original.opportunity[key].dexTotal.toFixed(4)}</div>
            ))
          : ''}
      </div>
    ),
  },
]

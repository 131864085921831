import s from './CexManager.module.css'

export const COLUMNS = [
  {
    Header: 'Broker',
    accessor: 'name',
    Cell: ({ value, ...cell }) => (
      <div className='flex-row align-center'>
        {cell.onSettings && (
          <i className={`${s.accessBtn} fas fa-cog`} onClick={() => cell.onSettings(value, cell.row.original.id)} />
        )}
        {value}
        <i
          style={{ marginLeft: 'auto', color: cell.row.original.authorized ? 'green' : 'red' }}
          className='fas fa-key'
        />
      </div>
    ),
  },
]

import {
  Backdrop,
  Button,
  Divider,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  TextField,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import arbitrageAPI from '../../../api/arbitrage-api'
import botAPI from '../../../api/bot-api'
import useDebounce from '../../../hooks/use-debounce'
import s from './BotEditor.module.css'

const initialBot = {
  cex_symbol_left: '',
  cex_symbol_right: '',
  dex_symbol_left: '',
  dex_symbol_right: '',
  exchange_from: '',
  exchange_to: '',
  amount: {},
  pmin: '',
}

const BotEditor = ({ id, show, fill, onClose, setPopup, refreshBots, archived }) => {
  const exchangesOptions = useSelector((state) => state.settings.availableExchanges)
  const [pairQuery, setPairQuery] = useState('')
  const [isPairFetching, setIsPairFetching] = useState(false)
  const [pairsOptions, setPairsOptions] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [values, setValues] = useState(initialBot)

  const debouncedPairQuery = useDebounce(pairQuery, 400)

  useEffect(() => {
    if (!debouncedPairQuery) {
      return
    }
    ;(async () => {
      setIsPairFetching(true)
      const data = await arbitrageAPI.getPairs(debouncedPairQuery)
      setPairsOptions(data?.data)
      setIsPairFetching(false)
    })()
  }, [debouncedPairQuery])

  useEffect(() => {
    if (fill) setValues(fill)

    return () => {
      setValues(initialBot)
    }
  }, [fill, show])

  const saveBot = async () => {
    setIsSaving(true)

    try {
      if (id) values.id = id

      const isAmountNotValid = Object.keys(values.amount).some(key => !values.amount[key].amount || !values.amount[key].profit)
      if (isAmountNotValid) {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: `Validation error. All Amount and Profit fields should not be empty`,
          severity: 'error'
        }))
        return
      }

      const response = await botAPI.saveBot(values)
      if (response === 'success') {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'Bot saved successfully!',
          severity: 'success',
        }))
        onClose()
        refreshBots()
        setValues(initialBot)
      } else {
        if (typeof response === 'string') {
          throw Error(response)
        }
        let errors = ''
        for (let key in response) {
          if (response?.hasOwnProperty(key)) {
            errors += `${response[key][0]}\n`
          }
        }
        throw Error(errors)
      }
    } catch (err) {
      setPopup((prev) => ({ ...prev, open: true, message: err.message, severity: 'error' }))
    } finally {
      setIsSaving(false)
    }
  }

  const handleChange = (e) => setValues((values) => ({ ...values, [e.target.name]: e.target.value }))

  const handlePairQueryChange = (value) => setPairQuery(value)

  const createAmountFields = (direction) => {
    setValues((prev) => {
      const keys = Object.keys(prev.amount).length > 0 ? Object.keys(prev.amount) : [0]
      const maxKey = Math.max(...keys)
      return { ...prev, amount: { ...prev.amount, [maxKey + 1]: { direction: direction } } }
    })
  }

  const changeAmountState = (e, index, key) => {
    setValues((prev) => ({
      ...prev,
      amount: { ...prev.amount, [index]: { ...prev.amount[index], [key]: e.target.value } },
    }))
  }

  return (
    <>
      <Modal
        aria-labelledby='modal-graph'
        aria-describedby='modal-graph'
        className={s.modal}
        open={show}
        onClose={() => {
          onClose()
          setValues(initialBot)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Paper className={s.settings}>
            <h4 style={{ marginBottom: 8 }}>{id ? `Edit bot #${id}` : 'Create bot'}</h4>
            <div className='flex-row' style={{ gap: 10 }}>
              <div className='column'>
                <FormControl style={{ marginBottom: 8 }}>
                  <InputLabel className={s.inputLabel} htmlFor={'cex_symbol_left'}>
                    CEX left
                  </InputLabel>
                  <TextField
                    name='cex_symbol_left'
                    value={values.cex_symbol_left}
                    onChange={handleChange}
                    size='small'
                    id='cex_symbol_left'
                    inputProps={{ min: 0 }}
                    disabled={archived}
                  />
                </FormControl>
                <FormControl style={{ marginBottom: 8 }}>
                  <InputLabel className={s.inputLabel} htmlFor={'cex_symbol_right'}>
                    CEX right
                  </InputLabel>
                  <TextField
                    name='cex_symbol_right'
                    value={values.cex_symbol_right}
                    onChange={handleChange}
                    size='small'
                    id='cex_symbol_right'
                    inputProps={{ min: 0 }}
                    disabled={archived}
                  />
                </FormControl>
                <FormControl style={{ marginBottom: 8 }}>
                  <InputLabel className={s.inputLabel} htmlFor={'dex_symbol_left'}>
                    DEX left
                  </InputLabel>
                  <TextField
                    name='dex_symbol_left'
                    value={values.dex_symbol_left}
                    onChange={handleChange}
                    size='small'
                    id='dex_symbol_left'
                    inputProps={{ min: 0 }}
                    disabled={archived}
                  />
                </FormControl>
                <FormControl style={{ marginBottom: 8 }}>
                  <InputLabel className={s.inputLabel} htmlFor={'dex_symbol_right'}>
                    DEX right
                  </InputLabel>
                  <TextField
                    name='dex_symbol_right'
                    value={values.dex_symbol_right}
                    onChange={handleChange}
                    size='small'
                    id='dex_symbol_right'
                    inputProps={{ min: 0 }}
                    disabled={archived}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel className={s.inputLabel} htmlFor={'from'}>
                    From
                  </InputLabel>
                  <Select
                    inputIt='From'
                    styles={{
                      control: (base) => ({ ...base, width: 250, marginBottom: 10 }),
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    options={exchangesOptions}
                    value={[{ name: values.exchange_from }]}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.id}
                    onChange={(e) => handleChange({ target: { name: 'exchange_from', value: e.name } })}
                    onInputChange={handlePairQueryChange}
                    isDisabled={archived}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel style={{ position: 'static', transform: 'none', fontSize: 12 }} htmlFor={'to'}>
                    To
                  </InputLabel>
                  <Select
                    inputIt='to'
                    styles={{
                      control: (base) => ({ ...base, width: 250, marginBottom: 10 }),
                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    options={exchangesOptions}
                    value={[{ name: values.exchange_to }]}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.id}
                    onChange={(e) => handleChange({ target: { name: 'exchange_to', value: e.name } })}
                    onInputChange={handlePairQueryChange}
                    isDisabled={archived}
                  />
                </FormControl>
              </div>
              <div className='column'>
                <FormControl style={{ marginBottom: 8 }}>
                  <InputLabel className={s.inputLabel} htmlFor={'pmin'}>
                    Pmin
                  </InputLabel>
                  <TextField
                    name='pmin'
                    value={values.pmin}
                    onChange={handleChange}
                    size='small'
                    type='number'
                    id='pmin'
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                    }}
                    inputProps={{ min: 0 }}
                    disabled={archived}
                  />
                </FormControl>
                <div className='flex-row align-center'>
                  <h5 style={{ marginRight: 8 }}>Buy direction:</h5>
                  <IconButton size='small' onClick={() => createAmountFields('buy')}>
                    <i className='fas fa-plus' />
                  </IconButton>
                </div>
                {Object.keys(values.amount).map(
                  (key) =>
                    values.amount[key].direction === 'buy' && (
                      <FormControl key={key} style={{ marginBottom: 8 }}>
                        <div className='flex-row align-center'>
                          <div className='column'>
                            <InputLabel className={s.inputLabel} htmlFor={'amount'}>
                              Amount #{key}
                            </InputLabel>
                            <TextField
                              name='amount'
                              value={values.amount[key]?.amount}
                              onChange={(e) => changeAmountState(e, key, 'amount')}
                              size='small'
                              type='number'
                              id='amount'
                              inputProps={{ min: 1 }}
                              style={{ marginRight: 8 }}
                              disabled={archived}
                            />
                          </div>
                          <div className='column'>
                            <InputLabel className={s.inputLabel} htmlFor={'profit'}>
                              Profit #{key}
                            </InputLabel>
                            <TextField
                              name='profit'
                              value={values.amount[key]?.profit}
                              onChange={(e) => changeAmountState(e, key, 'profit')}
                              size='small'
                              type='number'
                              id='profit'
                              inputProps={{ min: 1 }}
                              style={{ marginRight: 8 }}
                              InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                              }}
                              disabled={archived}
                            />
                          </div>
                          <IconButton
                            size='small'
                            onClick={() => {
                              let newAmount = { ...values.amount }
                              delete newAmount[key]
                              setValues((prev) => ({ ...prev, amount: newAmount }))
                            }}
                            style={{ marginTop: 12 }}
                          >
                            <i className='fas fa-minus' />
                          </IconButton>
                        </div>
                      </FormControl>
                    ),
                )}
                <Divider style={{ marginTop: 10 }} />
                <div className='flex-row align-center'>
                  <h5 style={{ marginRight: 8 }}>Sell direction:</h5>
                  <IconButton size='small' onClick={() => createAmountFields('sell')}>
                    <i className='fas fa-plus' />
                  </IconButton>
                </div>
                {Object.keys(values.amount).map(
                  (key) =>
                    values.amount[key].direction === 'sell' && (
                      <FormControl key={key} style={{ marginBottom: 8 }}>
                        <div className='flex-row align-center'>
                          <div className='column'>
                            <InputLabel className={s.inputLabel} htmlFor={'amount'}>
                              Amount #{key}
                            </InputLabel>
                            <TextField
                              name='amount'
                              value={values.amount[key]?.amount}
                              onChange={(e) => changeAmountState(e, key, 'amount')}
                              size='small'
                              type='number'
                              id='amount'
                              inputProps={{ min: 1 }}
                              style={{ marginRight: 8 }}
                              disabled={archived}
                            />
                          </div>
                          <div className='column'>
                            <InputLabel className={s.inputLabel} htmlFor={'profit'}>
                              Profit #{key}
                            </InputLabel>
                            <TextField
                              name='profit'
                              value={values.amount[key]?.profit}
                              onChange={(e) => changeAmountState(e, key, 'profit')}
                              size='small'
                              type='number'
                              id='profit'
                              inputProps={{ min: 1 }}
                              style={{ marginRight: 8 }}
                              InputProps={{
                                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                              }}
                              disabled={archived}
                            />
                          </div>
                          <IconButton
                            size='small'
                            onClick={() => {
                              let newAmount = { ...values.amount }
                              delete newAmount[key]
                              setValues((prev) => ({ ...prev, amount: newAmount }))
                            }}
                            style={{ marginTop: 12 }}
                          >
                            <i className='fas fa-minus' />
                          </IconButton>
                        </div>
                      </FormControl>
                    ),
                )}
              </div>
            </div>
            <div className={s.saveBtn}>
              <Button disabled={isSaving || archived} onClick={saveBot} variant={'outlined'} color='inherit'>
                Save
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  )
}

export default BotEditor

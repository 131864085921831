import { redirectToCognitoPage } from '../api/auth-api'
import { getIsAuth } from '../storage/user-storage'

const withAuthRedirect = (Component) => {
  return (props) => {
    const isAuth = getIsAuth()

    if (isAuth) return <Component {...props} />
    redirectToCognitoPage()
  }
}
export default withAuthRedirect

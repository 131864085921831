import { instance } from './axios'

const arbitrageAPI = {
  getExchanges: () => {
    return instance
      .get('/exchange')
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },

  saveExchanges: (exchanges) => {
    return instance
      .post(`/exchange`, { id: exchanges })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },

  getPairs: (name, onlyTopDeals = false) => {
    const params = {}
    if (name) params.name = name
    if (onlyTopDeals) params.onlyTopDeals = 1

    return instance
      .get(`/pair`, { params: params })
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },

  savePairs: (pairs) => {
    return instance
      .post(`/pair`, { id: pairs })
      .then((res) => {
        return res.data
      })
      .catch((err) => console.log(err))
  },

  getArbitrationData: () => {
    return instance
      .get(`/get-arbitrage-prices`) //.get(`/arbitration?pairs=${pairs.join(',')}`)
      .then((res) => res.data)
      .catch((err) => console.log(err))
  },

  getSavedExchanges: () => {
    return instance
      .get(`/saved-exchanges`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },

  getSavedPairs: () => {
    return instance
      .get(`/saved-pairs`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },

  getChartData: (pair, bid, ask) => {
    return instance
      .get(`/chart?pair=${pair}&bid_from_exchange=${bid}&ask_from_exchange=${ask}`)
      .then((res) => res.data.data)
      .catch((err) => console.log(err))
  },

  /*getCoins: () => {
        return instance
            .get("/coins")
            .then((res) => res.data)
            .catch((err) => console.log(err));
    },

    getBots: () => {
        return instance
            .get("/bots")
            .then((res) => res.data)
            .catch((err) => console.log(err));
    },*/
}

export default arbitrageAPI

import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useSortBy, useTable } from 'react-table'
import botAPI from '../../api/bot-api'
import Preloader from '../../components/Preloader/Preloader'
import s from './BotOpportunities.module.css'
import { COLUMNS } from './columns'
import { useInterval } from '../../hooks/use-interval'
import { alphanumericFalsyLast } from '../../utilities/sorting'

const BotOpportunities = () => {
  const sortTypes = useMemo(() => ({ alphanumericFalsyLast }), [])
  const columns = useMemo(() => COLUMNS, [])
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      sortTypes,
      disableMultiSort: true,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useSortBy,
  )

  const fetchData = async () => {
    const response = await botAPI.getOpportunities()

    const arr = response
      ? Object.keys(response)
          .map((key) => response[key])
          .flat()
      : []
    if (arr && arr?.length > 0) {
      setData(arr)
    }
    setIsFetching(false)
  }

  useInterval(fetchData, 1000)

  useEffect(() => {
    setIsFetching(true)
    fetchData()
  }, [])

  if (isFetching) return <Preloader />

  return (
    <Paper className={s.paper} elevation={2}>
      <Typography variant={'h5'} style={{ padding: '0 10px' }}>
        Bot opportunities
      </Typography>
      <TableContainer className={s.table}>
        {rows.length > 0 && (
          <MuiTable stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        style={{
                          display: 'flex !important',
                        }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        <div className='flex-row align-center' style={{ gap: 4 }}>
                          <span
                            style={{
                              userSelect: 'none',
                            }}
                          >
                            {column.render('Header')}
                          </span>
                          <i
                            style={{
                              fontSize: '11px',
                              marginLeft: '2px',
                              color: !column.isSorted && 'transparent',
                            }}
                            className={`fas fa-chevron-${
                              column.isSorted ? (column.isSortedDesc ? 'down' : 'up') : 'up'
                            }`}
                          />
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <TableCell {...cell.getCellProps()}>{cell.render('Cell', {})}</TableCell>
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </MuiTable>
        )}
        {rows.length <= 0 && <h2 style={{ margin: '8px' }}>No results</h2>}
      </TableContainer>
    </Paper>
  )
}

export default BotOpportunities
